import { IconButton, type IconButtonProps } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';

export type FileStatusButtonProps = IconButtonProps & {
  label: string;
};

export const FileStatusButton = ({ onClick, label, ...restOfProps }: FileStatusButtonProps) => {
  return (
    <Tooltip label={label} sideOffset={4} side="top">
      <IconButton appearance="ghost" color="grey" onClick={onClick} size="small" label={label} {...restOfProps} />
    </Tooltip>
  );
};
