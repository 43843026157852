import { createAction } from '@reduxjs/toolkit';

import { type ContentCollectionCustomField, type ContentCollectionState } from './types';

export const setApplyAllAction = createAction<{ applyAll: ContentCollectionState['applyAll'] }>('SET_APPLY_ALL');

export const setCustomFieldsAction = createAction<{ customFields: ContentCollectionState['customFields'] }>(
  'SET_CUSTOM_FIELDS',
);

export const setCustomFieldPlainTextValueAction = createAction<{ customField: ContentCollectionCustomField }>(
  'SET_CUSTOM_FIELD_PLAIN_TEXT_VALUE',
);

export const setCustomFieldDateValueAction = createAction<{ customField: ContentCollectionCustomField }>(
  'SET_CUSTOM_FIELD_DATE_VALUE',
);

export const setCustomFieldValuesAction = createAction<{ customField: ContentCollectionCustomField }>(
  'SET_CUSTOM_FIELD_VALUES',
);

export const setCustomFieldValueAction = createAction<{ customField: ContentCollectionCustomField }>(
  'SET_CUSTOM_FIELD_VALUE',
);

export const setViewAction = createAction<{ view: ContentCollectionState['view'] }>('SET_VIEW');

export const setFormAction = createAction<{ form: ContentCollectionState['form'] }>('SET_FORM');

export const setShortIdAction = createAction<{ shortId: ContentCollectionState['shortId'] }>('SET_SHORT_ID');
