import { Exclamation } from '@air/next-icons';
import { animated, config, useSpring } from '@react-spring/web';
import { memo } from 'react';

export const FileStatusIconErrored = memo(() => {
  const [props] = useSpring(
    () => ({
      from: { scale: 0 },
      to: { scale: 1 },
      config: config.wobbly,
    }),
    [],
  );

  return (
    <div aria-hidden className="mr-3 flex size-10 items-center justify-center rounded-full bg-red-3">
      <animated.div className="flex items-center justify-center" style={props}>
        <Exclamation className="size-6 fill-red-9" />
      </animated.div>
    </div>
  );
});

FileStatusIconErrored.displayName = 'FileStatusIconErrored';
