import { Close, DoubleChevronDown, DoubleChevronUp, Pause, Play } from '@air/next-icons';
import { memo } from 'react';

import { FileStatus } from '../../constants/FileStatus';
import { FileStatusButton } from './FileStatusButton';

export interface FileStatusPanelHeaderButtonsProps {
  onResume?: () => void;
  onCancel?: () => void;
  onPause?: () => void;
  onDismiss?: () => void;
  status?: FileStatus;
  isUploaderOpen: boolean;
  onToggle: (isOpen: boolean) => void;
  disableCancel?: boolean;
}

export const FileStatusPanelHeaderButtons = memo(
  ({
    onDismiss,
    onPause,
    onResume,
    status,
    onCancel,
    isUploaderOpen,
    onToggle,
    disableCancel,
  }: FileStatusPanelHeaderButtonsProps) => {
    return (
      <>
        {onPause && status === FileStatus.progressing && (
          <FileStatusButton label="Pause" onClick={onPause}>
            <Pause className="size-4" />
          </FileStatusButton>
        )}

        {onResume && status === FileStatus.paused && (
          <FileStatusButton label="Resume" onClick={onResume}>
            <Play className="size-4" />
          </FileStatusButton>
        )}

        {isUploaderOpen ? (
          <FileStatusButton label="Minimize" onClick={() => onToggle(false)}>
            <DoubleChevronDown className="size-4" />
          </FileStatusButton>
        ) : (
          <FileStatusButton label="Expand" onClick={() => onToggle(true)}>
            <DoubleChevronUp className="size-4" />
          </FileStatusButton>
        )}

        {onDismiss && (disableCancel || status === FileStatus.completed) && (
          <FileStatusButton data-testid="CLOSE_UPLOADS_BUTTON" label="Close" onClick={onDismiss}>
            <Close className="size-4" />
          </FileStatusButton>
        )}

        {onCancel && status !== FileStatus.completed && (
          <FileStatusButton label="Cancel" onClick={onCancel}>
            <Close className="size-4" />
          </FileStatusButton>
        )}
      </>
    );
  },
);

FileStatusPanelHeaderButtons.displayName = 'FileStatusPanelHeaderButtons';
