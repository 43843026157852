import { createSelector } from '@reduxjs/toolkit';

import { type ContentCollectionState } from './types';

export const contentCollectionSelector = ({ contentCollection }: { contentCollection: ContentCollectionState }) =>
  contentCollection;

export const contentCollectionApplyAllSelector = createSelector(contentCollectionSelector, ({ applyAll }) => applyAll);

export const contentCollectionCustomFieldsSelector = createSelector(
  contentCollectionSelector,
  ({ customFields }) => customFields,
);

export const contentCollectionViewSelector = createSelector(contentCollectionSelector, ({ view }) => view);

export const contentCollectionFormSelector = createSelector(contentCollectionSelector, ({ form }) => form);

export const contentCollectionFormTitleSelector = createSelector(
  contentCollectionFormSelector,
  (state) => state?.title,
);

export const contentCollectionFormDescriptionSelector = createSelector(
  contentCollectionFormSelector,
  (state) => state?.description,
);

export const contentCollectionShortIdSelector = createSelector(contentCollectionSelector, ({ shortId }) => shortId);
