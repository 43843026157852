import { tailwindMerge } from '@air/tailwind-variants';
import { type ComponentPropsWithoutRef, memo, type ReactNode } from 'react';

export type FileStatusPanelItemProps = Pick<ComponentPropsWithoutRef<'div'>, 'className' | 'onClick'> & {
  title: ReactNode;
  subtitle?: ReactNode;
  avatar: ReactNode;
  buttons: ReactNode;
  previewLoader?: ReactNode;
};

export const FileStatusPanelItem = memo(
  ({ className, title, subtitle, avatar, buttons, previewLoader, onClick }: FileStatusPanelItemProps) => (
    <div className={tailwindMerge('flex justify-between', className)} onClick={onClick}>
      {previewLoader}
      <div className="flex shrink py-1">
        {avatar}

        <div className="flex flex-1 flex-col justify-center">
          {title}
          {subtitle}
        </div>
      </div>

      <div className="pane-item-buttons flex shrink-0 justify-end gap-1 pl-2.5">{buttons}</div>
    </div>
  ),
);

FileStatusPanelItem.displayName = 'FileStatusPanelItem';
