import { memo } from 'react';

import { FileStatus } from '../../constants/FileStatus';
import { FileStatusIconCompleted } from './FileStatusIconCompleted';
import { FileStatusIconErrored } from './FileStatusIconErrored';
import { FileStatusIconPaused } from './FileStatusIconPaused';
import { FileStatusIconProgressing } from './FileStatusIconProgressing';

export type FileStatusIconProps = {
  status: FileStatus;
};

export const FileStatusIcon = memo(({ status }: FileStatusIconProps) => {
  switch (status) {
    case FileStatus.completed:
      return <FileStatusIconCompleted />;
    case FileStatus.paused:
      return <FileStatusIconPaused />;
    case FileStatus.errored:
      return <FileStatusIconErrored />;
    case FileStatus.progressing:
    default:
      return <FileStatusIconProgressing />;
  }
});

FileStatusIcon.displayName = 'FileStatusIcon';
