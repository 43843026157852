import { PublicClip } from '@air/api';
import { ContentCollectionCustomField } from '@air/feature-content-collection/redux';
import { Upload } from '@air/redux-uploader';
import { createAction } from '@reduxjs/toolkit';

import { BoardCollectionState } from './types';

export const setHasSubmittedAction = createAction<{ hasSubmitted: BoardCollectionState['hasSubmitted'] }>(
  'SET_BOARD_COLLECTION_HAS_SUBMITTED',
);

export const setAttemptedSubmissionAction = createAction<{
  attemptedSubmission: BoardCollectionState['attemptedSubmission'];
}>('SET_BOARD_COLLECTION_ATTEMPTED_SUBMISSION');

export const setSessionIdAction = createAction<{ sessionId: BoardCollectionState['sessionId'] }>(
  'SET_BOARD_COLLECTION_SESSION_ID',
);

export const deleteBoardCollectionClipsAction = createAction<{ clipIds: string[] }>('DELETE_BOARD_COLLECTION_CLIPS');

export const updateBoardCollectionClipsAction = createAction<{
  clips: (Partial<PublicClip> & Pick<PublicClip, 'id'>)[];
}>('UPDATE_BOARD_COLLECTION_CLIPS');

export const updateBoardCollectionCustomFieldsInAllClipsAction = createAction<{
  customField: ContentCollectionCustomField;
}>('UPDATE_BOARD_COLLECTION_CUSTOM_FIELDS_IN_ALL_CLIPS');

export const clearBoardCollectionClipsAction = createAction('CLEAR_BOARD_COLLECTION_CLIPS');

export const setFormAction = createAction<{ form: BoardCollectionState['form'] }>('SET_BOARD_COLLECTION_FORM');

export const addUploadingClipToBoardCollectionAction = createAction<{ clip: PublicClip; uploadId: Upload['id'] }>(
  'ADD_UPLOADING_CLIP_TO_BOARD_COLLECTION',
);

export const setBoardCollectionViewAction = createAction<{ view: BoardCollectionState['view'] }>(
  'SET_BOARD_COLLECTION_VIEW',
);
