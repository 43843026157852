import { FileExtensionPreview, ImageContainer } from '@air/component-file-extension-preview';
import { type UploadStatus } from '@air/redux-uploader';
import classNames from 'classnames';

import { SHARED_PANEL_ITEM_THUMBNAIL_CLASS_NAMES } from '../../constants/ClassNames';
import { FileStatusFolderStatus } from './FileStatusFolderStatus';

export type FileStatusThumbnailProps = {
  ext?: string;
  status: UploadStatus;
  thumbnailSource?: string;
};

export const FileStatusThumbnail = ({ ext = '', status, thumbnailSource }: FileStatusThumbnailProps) => {
  return thumbnailSource ? (
    <img className="mr-3 inline-block size-10 shrink-0 rounded-sm object-cover" src={thumbnailSource} alt="" />
  ) : ext === 'folder' ? (
    <FileStatusFolderStatus status={status} />
  ) : (
    <ImageContainer
      ext={ext}
      processing={false}
      borderRadius={2}
      className={classNames(SHARED_PANEL_ITEM_THUMBNAIL_CLASS_NAMES, '!grow-0 rounded-sm')}
    >
      <FileExtensionPreview ext={ext} size="small" className="max-w-[40px]" />
    </ImageContainer>
  );
};
