import { Sync } from '@air/next-icons';

export const FileStatusIconProgressing = () => {
  return (
    <div
      aria-hidden
      className="mr-3 flex size-10 animate-spin items-center justify-center rounded-full bg-indigo-1 motion-reduce:animate-none"
    >
      <Sync className="size-6 fill-indigo-9" />
    </div>
  );
};
