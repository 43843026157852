import { UploadStatus } from '@air/redux-uploader';
import { ComponentProps } from 'react';

import { SHARED_PANEL_ITEM_THUMBNAIL_CLASS_NAMES } from '../../constants/ClassNames';

export const FileStatusFolderStatus = ({ status }: { status: UploadStatus }) => {
  switch (status) {
    case UploadStatus['completed']:
      return <FolderSuccess className={SHARED_PANEL_ITEM_THUMBNAIL_CLASS_NAMES} />;
    case UploadStatus['paused']:
      return <FolderPause className={SHARED_PANEL_ITEM_THUMBNAIL_CLASS_NAMES} />;
    case UploadStatus['failed']:
    case UploadStatus['rejected']:
      return <FolderError className={SHARED_PANEL_ITEM_THUMBNAIL_CLASS_NAMES} />;
    default:
      return <FolderProgress className={SHARED_PANEL_ITEM_THUMBNAIL_CLASS_NAMES} />;
  }
};

const FolderSuccess = (props: ComponentProps<'svg'>) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path
          d="M38 34.875C38 35.9796 37.1046 36.875 36 36.875H4C2.89543 36.875 2 35.9796 2 34.875V5.125C2 4.02043 2.89543 3.125 4 3.125H16.2784C16.9794 3.125 17.6293 3.49205 17.9912 4.09243L18.704 5.27481L20 7.42461H36C37.1046 7.42461 38 8.32004 38 9.42461V34.875Z"
          fill="#6FA2FF"
        />
        <path
          d="M1.98291 9.875H37.9829V34.875C37.9829 35.9796 37.0875 36.875 35.9829 36.875H3.98291C2.87834 36.875 1.98291 35.9796 1.98291 34.875V9.875Z"
          fill="#90B8FF"
        />
      </g>
      <rect x="22.5" y="22.5" width="17" height="17" rx="8.5" fill="#07B3BE" />
      <g clipPath="url(#clip0)">
        <path
          d="M35.7462 27.123C35.9768 27.3845 35.9519 27.7834 35.6905 28.014C34.1513 29.3721 32.59 31.0093 31.4085 32.3137C30.819 32.9645 30.3266 33.5297 29.9819 33.9317C29.8095 34.1327 29.6742 34.2929 29.5822 34.4024C29.5363 34.4572 29.5012 34.4993 29.4777 34.5275L35.7462 27.123ZM35.7462 27.123C35.5155 26.8616 35.1166 26.8367 34.8552 27.0673C33.2648 28.4706 31.6675 30.1472 30.4728 31.4661C29.8841 32.1161 29.3906 32.682 29.0408 33.0897M35.7462 27.123L29.0408 33.0897M29.0408 33.0897L27.7127 30.8957C27.5322 30.5974 27.1441 30.502 26.8458 30.6825C26.5476 30.8631 26.4522 31.2512 26.6327 31.5495L28.4157 34.495C28.5216 34.67 28.7058 34.7828 28.9099 34.7977C29.1139 34.8125 29.3125 34.7275 29.4427 34.5697L29.0408 33.0897ZM29.4512 34.5594L29.4776 34.5276L29.4428 34.5696L29.451 34.5596L29.4512 34.5594Z"
          fill="white"
          stroke="white"
          strokeWidth="0.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="22.5" y="22.5" width="17" height="17" rx="8.5" stroke="white" />
      <defs>
        <clipPath id="clip0">
          <rect width="11.782" height="11.782" fill="white" transform="translate(24.9636 24.9636)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const FolderPause = (props: ComponentProps<'svg'>) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M38 34.875C38 35.9796 37.1046 36.875 36 36.875H4C2.89543 36.875 2 35.9796 2 34.875V5.125C2 4.02043 2.89543 3.125 4 3.125H16.2784C16.9794 3.125 17.6293 3.49205 17.9912 4.09243L18.704 5.27481L20 7.42461H36C37.1046 7.42461 38 8.32004 38 9.42461V34.875Z"
        fill="#6FA2FF"
      />
      <path
        d="M1.98291 9.875H37.9829V34.875C37.9829 35.9796 37.0875 36.875 35.9829 36.875H3.98291C2.87834 36.875 1.98291 35.9796 1.98291 34.875V9.875Z"
        fill="#90B8FF"
      />
      <rect x="22.5" y="22.5" width="17" height="17" rx="8.5" fill="#DCDCDC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4975 27.8173C29.3758 27.6942 29.2107 27.625 29.0387 27.625C28.9522 27.6231 28.8663 27.6385 28.7858 27.6706C28.7054 27.7027 28.6321 27.7507 28.5703 27.8119C28.5084 27.873 28.4593 27.946 28.4258 28.0266C28.3922 28.1073 28.375 28.1938 28.375 28.2813V33.7188C28.375 33.8063 28.3922 33.8928 28.4258 33.9734C28.4593 34.0541 28.5084 34.1271 28.5703 34.1882C28.6321 34.2494 28.7054 34.2974 28.7858 34.3295C28.8663 34.3616 28.9522 34.377 29.0387 34.375C29.2107 34.375 29.3758 34.3059 29.4975 34.1828C29.6191 34.0598 29.6875 33.8928 29.6875 33.7188V28.2813C29.6875 28.1073 29.6191 27.9403 29.4975 27.8173ZM33.451 27.8225C33.3297 27.6998 33.1648 27.629 32.9913 27.6251C32.8153 27.6251 32.6464 27.6942 32.5219 27.8173C32.3974 27.9403 32.3275 28.1073 32.3275 28.2813V33.7188C32.3275 33.8929 32.3974 34.0598 32.5219 34.1829C32.6464 34.3059 32.8153 34.3751 32.9913 34.3751C33.1648 34.3711 33.3297 34.3003 33.451 34.1776C33.5722 34.055 33.64 33.8903 33.64 33.7188V28.2813C33.64 28.1098 33.5722 27.9451 33.451 27.8225Z"
        fill="#666666"
      />
      <rect x="22.5" y="22.5" width="17" height="17" rx="8.5" stroke="white" />
    </svg>
  );
};

const FolderError = (props: ComponentProps<'svg'>) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M38 34.875C38 35.9796 37.1046 36.875 36 36.875H4C2.89543 36.875 2 35.9796 2 34.875V5.125C2 4.02043 2.89543 3.125 4 3.125H16.2784C16.9794 3.125 17.6293 3.49205 17.9912 4.09243L18.704 5.27481L20 7.42461H36C37.1046 7.42461 38 8.32004 38 9.42461V34.875Z"
        fill="#6FA2FF"
      />
      <path
        d="M1.98291 9.875H37.9829V34.875C37.9829 35.9796 37.0875 36.875 35.9829 36.875H3.98291C2.87834 36.875 1.98291 35.9796 1.98291 34.875V9.875Z"
        fill="#90B8FF"
      />
      <rect x="22.5" y="22.5" width="17" height="17" rx="8.5" fill="#FF5050" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.6757 27.6758C27.9118 27.4396 28.2947 27.4396 28.5309 27.6758L30.9253 30.0702L33.3197 27.6758C33.5559 27.4396 33.9388 27.4396 34.1749 27.6758C34.4111 27.9119 34.4111 28.2948 34.1749 28.531L31.7805 30.9254L34.1749 33.3198C34.4111 33.556 34.4111 33.9389 34.1749 34.1751C33.9388 34.4112 33.5559 34.4112 33.3197 34.1751L30.9253 31.7806L28.5309 34.1751C28.2947 34.4112 27.9118 34.4112 27.6757 34.1751C27.4395 33.9389 27.4395 33.556 27.6757 33.3198L30.0701 30.9254L27.6757 28.531C27.4395 28.2948 27.4395 27.9119 27.6757 27.6758Z"
        fill="white"
      />
      <rect x="22.5" y="22.5" width="17" height="17" rx="8.5" stroke="white" />
    </svg>
  );
};

const FolderProgress = (props: ComponentProps<'svg'>) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M38 34.875C38 35.9796 37.1046 36.875 36 36.875H4C2.89543 36.875 2 35.9796 2 34.875V5.125C2 4.02043 2.89543 3.125 4 3.125H16.2784C16.9794 3.125 17.6293 3.49205 17.9912 4.09243L18.704 5.27481L20 7.42461H36C37.1046 7.42461 38 8.32004 38 9.42461V34.875Z"
        fill="#6FA2FF"
      />
      <path
        d="M1.98291 9.875H37.9829V34.875C37.9829 35.9796 37.0875 36.875 35.9829 36.875H3.98291C2.87834 36.875 1.98291 35.9796 1.98291 34.875V9.875Z"
        fill="#90B8FF"
      />
      <rect x="22.5" y="22.5" width="17" height="17" rx="8.5" fill="#2943FF" />
      <rect x="23" y="23" width="16" height="16" rx="8" fill="#2943FF" />
      <path
        d="M27.5121 35.7753L27.2495 33.9746C27.249 33.971 27.2487 33.9674 27.2487 33.9638C27.2487 33.7985 27.2909 33.6715 27.3738 33.547C27.3766 33.5429 27.3797 33.5391 27.3832 33.5356C27.5063 33.4125 27.6745 33.3261 27.8489 33.3261H29.5745C29.7128 33.3261 29.8352 33.3951 29.9213 33.4919C30.0073 33.5886 30.0622 33.7185 30.0622 33.8512C30.0622 34.1553 29.841 34.3764 29.537 34.3764H28.9767C29.0783 34.4331 29.2124 34.5015 29.374 34.5699C29.7661 34.736 30.3187 34.9016 30.9625 34.9016C33.3902 34.9016 34.8639 32.804 34.8639 31.0753C34.8639 30.811 34.8637 30.5551 34.8274 30.3373L34.8271 30.3356C34.785 30.0406 34.954 29.7712 35.2267 29.7258C35.4898 29.6819 35.7559 29.8563 35.8007 30.1251L35.8012 30.1281L35.8014 30.1298C35.8388 30.429 35.8768 30.733 35.8768 31.0753C35.8768 33.2487 34.011 35.8395 31 35.8395C30.1998 35.8395 29.524 35.6394 29.0484 35.4397C28.8105 35.3398 28.6224 35.2398 28.4934 35.1645C28.4698 35.1508 28.4482 35.1379 28.4287 35.1259L28.4861 35.6425C28.5298 35.9532 28.3048 36.1709 28.0488 36.2136C27.7693 36.2602 27.5541 36.0277 27.5123 35.7768L27.5121 35.7753Z"
        fill="white"
      />
      <path
        d="M33.0233 27.5865C32.9218 27.5299 32.7877 27.4615 32.6261 27.393C32.2339 27.2269 31.6813 27.0613 31.0376 27.0613C28.6098 27.0613 27.1362 29.1589 27.1362 30.8877C27.1362 31.152 27.1364 31.4079 27.1726 31.6256L27.1729 31.6273C27.2151 31.9224 27.0461 32.1917 26.7734 32.2371C26.5102 32.281 26.2441 32.1066 26.1993 31.8379L26.1988 31.8348L26.1987 31.8332C26.1613 31.534 26.1233 31.23 26.1233 30.8877C26.1233 28.6767 27.9893 26.1231 31.0005 26.161C31.8005 26.1611 32.4762 26.361 32.9517 26.5607C33.1895 26.6606 33.3777 26.7606 33.5067 26.8359C33.5303 26.8496 33.5518 26.8626 33.5714 26.8745L33.514 26.358C33.4703 26.0473 33.6953 25.8295 33.9513 25.7869C34.2307 25.7403 34.4459 25.9727 34.4877 26.2235L34.7878 27.9866C34.7885 27.9907 34.7889 27.9949 34.7889 27.9992C34.7889 28.1645 34.7467 28.2914 34.6638 28.4159C34.661 28.42 34.6579 28.4238 34.6544 28.4273C34.5313 28.5504 34.3631 28.6369 34.1887 28.6369H32.4255C32.2872 28.6369 32.1648 28.5679 32.0787 28.471C31.9928 28.3743 31.9379 28.2444 31.9379 28.1117C31.9379 27.8077 32.159 27.5865 32.4631 27.5865H33.0233Z"
        fill="white"
      />
      <rect x="22.5" y="22.5" width="17" height="17" rx="8.5" stroke="white" />
    </svg>
  );
};
