import {
  FileStatusButton,
  FileStatusPanelItem,
  FileStatusSubtitle,
  FileStatusThumbnail,
  FileStatusTitle,
  FileTrackingItem,
} from '@air/feature-file-status/ui';
import { getSubtitleWithProgress } from '@air/feature-file-status/utils';
import { Close, Pause, Skip } from '@air/next-icons';
import { UploadStatus } from '@air/redux-uploader';
import classNames from 'classnames';
import { memo } from 'react';
import isEqual from 'react-fast-compare';

import { SHARED_PANEL_ITEM_CLASS_NAMES } from '../../constants';

export interface FileStatusTrackingPanelListItemProgressingProps {
  item: FileTrackingItem;
  onCancel?: (item: FileTrackingItem) => void;
  onPause?: (item: FileTrackingItem) => void;
  onSkip?: (item: FileTrackingItem) => void;
}

export const FileStatusTrackingPanelListItemProgressing = memo(
  ({
    item,
    item: {
      title,
      ext,
      status,
      onRowClick,
      previewLoader,
      thumbnailSource,
      avatar,
      subtitle,
      sizeInBytes,
      currentBytesUploaded,
    },
    onSkip,
    onCancel,
    onPause,
  }: FileStatusTrackingPanelListItemProgressingProps) => (
    <FileStatusPanelItem
      onClick={onRowClick}
      previewLoader={previewLoader}
      className={classNames(
        'hover:bg-grey-3 [&>.pane-item-buttons]:opacity-0 [&>.pane-item-buttons]:hover:opacity-100',
        SHARED_PANEL_ITEM_CLASS_NAMES,
        !!onRowClick && 'cursor-pointer',
      )}
      avatar={avatar ?? <FileStatusThumbnail ext={ext} status={status} thumbnailSource={thumbnailSource} />}
      title={<FileStatusTitle>{title}</FileStatusTitle>}
      subtitle={
        status === UploadStatus.uploading ? (
          <FileStatusSubtitle className="truncate">
            {subtitle ??
              getSubtitleWithProgress({
                title: 'Uploading...',
                uploadedBytes: currentBytesUploaded,
                sizeInBytes,
              })}
          </FileStatusSubtitle>
        ) : (
          <FileStatusSubtitle>Queued</FileStatusSubtitle>
        )
      }
      buttons={
        <>
          {onPause && (
            <FileStatusButton onClick={() => onPause(item)} label="Pause">
              <Pause className="size-4" />
            </FileStatusButton>
          )}
          {onSkip && (
            <FileStatusButton onClick={() => onSkip(item)} label="Skip">
              <Skip className="size-4" />
            </FileStatusButton>
          )}
          {onCancel && (
            <FileStatusButton onClick={() => onCancel(item)} label="Cancel">
              <Close className="size-4" />
            </FileStatusButton>
          )}
        </>
      }
    />
  ),
  isEqual,
);

FileStatusTrackingPanelListItemProgressing.displayName = 'FileStatusTrackingPanelListItemProgressing';
