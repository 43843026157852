import { tailwindMerge } from '@air/tailwind-variants';
import { memo, type ReactNode } from 'react';

import { FileStatus } from '../../constants/FileStatus';
import { FileStatusIcon } from './FileStatusIcon';
import { FileStatusPanelHeaderButtons, type FileStatusPanelHeaderButtonsProps } from './FileStatusPanelHeaderButtons';
import { FileStatusPanelItem } from './FileStatusPanelItem';
import { FileStatusSubtitle } from './FileStatusSubtitle';
import { FileStatusTitle } from './FileStatusTitle';

export type FileStatusPanelHeaderProps = FileStatusPanelHeaderButtonsProps & {
  title: ReactNode;
  subtitle: ReactNode;
  disableCancel?: boolean;
  status: FileStatus;
  className?: string;
};

export const FileStatusPanelHeader = memo(
  ({
    isUploaderOpen,
    onToggle,
    status,
    title,
    subtitle,
    disableCancel,
    onCancel,
    onDismiss,
    onPause,
    onResume,
    className,
  }: FileStatusPanelHeaderProps) => {
    return (
      <FileStatusPanelItem
        className={tailwindMerge('px-4 py-3', isUploaderOpen ? 'rounded-none' : 'rounded', className)}
        avatar={<FileStatusIcon status={status} />}
        title={
          <FileStatusTitle data-testid="UPLOADER_TITLE" className="font-semibold">
            {title}
          </FileStatusTitle>
        }
        subtitle={
          <FileStatusSubtitle className="truncate" data-testid="UPLOADER_SUBTITLE">
            {subtitle}
          </FileStatusSubtitle>
        }
        buttons={
          <FileStatusPanelHeaderButtons
            status={status}
            onCancel={onCancel}
            onResume={onResume}
            disableCancel={disableCancel}
            onPause={onPause}
            isUploaderOpen={isUploaderOpen}
            onDismiss={onDismiss}
            onToggle={onToggle}
          />
        }
      />
    );
  },
);

FileStatusPanelHeader.displayName = 'FileStatusPanelHeader';
