import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import { memo } from 'react';

import { SelectChipRemoveButton } from '~/components/Select/components/SelectChipRemoveButton';
import { SELECT_CHIP } from '~/components/Select/shared/testIDs';

import { DefaultChipType } from '../shared/types';

export const selectChip = tailwindVariants({
  base: 'my-[3px] mr-1.5 flex w-auto items-center justify-start rounded-full bg-grey-4 transition-colors',
  variants: {
    size: {
      large: 'px-2 py-0.5 text-12',
      'extra-large': 'px-3 py-1.5 text-14',
    },
  },
  defaultVariants: {
    size: 'large',
  },
});

type SelectChipVariants = VariantProps<typeof selectChip>;

interface MultiSelectChipProps<T extends DefaultChipType> extends SelectChipVariants {
  chip: T;
  onDeselect?: (deselected: T) => void;
  'data-testid'?: string;
}

const makeCustomStyles = (chip: DefaultChipType): React.CSSProperties => ({
  ...(chip.backgroundHexColor && { backgroundColor: chip.backgroundHexColor }),
  ...(chip.borderRadius && { borderRadius: chip.borderRadius }),
});

const _SelectChip = <T extends DefaultChipType>({
  chip,
  onDeselect,
  'data-testid': testId = SELECT_CHIP,
  size = 'large',
}: MultiSelectChipProps<T>) => {
  return (
    <div className={selectChip({ size })} style={makeCustomStyles(chip)}>
      <div
        className="truncate text-[length:inherit] font-medium text-grey-11"
        data-testid={`${testId}-${chip.label}`}
        style={{
          color: chip.fontHexColor,
        }}
      >
        {chip.label}
      </div>
      {!!onDeselect && <SelectChipRemoveButton chip={chip} onClick={onDeselect} />}
    </div>
  );
};

export const SelectChip = memo(_SelectChip) as typeof _SelectChip;
