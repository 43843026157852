import {
  FileStatusButton,
  FileStatusPanelItem,
  FileStatusSubtitle,
  FileStatusTitle,
  FileTrackingItem,
} from '@air/feature-file-status/ui';
import { Close, Refresh } from '@air/next-icons';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import classNames from 'classnames';
import { memo } from 'react';
import isEqual from 'react-fast-compare';

import { SHARED_PANEL_ITEM_CLASS_NAMES } from '../../constants/ClassNames';
import { FileStatusThumbnail } from './FileStatusThumbnail';

export interface FileStatusTrackingPanelListItemErroredProps {
  item: FileTrackingItem;
  error: string;
  onCancel?: (item: FileTrackingItem) => void;
  onRetry?: (item: FileTrackingItem) => void;
}

export const FileStatusTrackingPanelListItemErrored = memo(
  ({
    item: { ext, title, thumbnailSource, onRowClick, previewLoader, avatar, status },
    item,
    error,
    onCancel,
    onRetry,
  }: FileStatusTrackingPanelListItemErroredProps) => (
    <FileStatusPanelItem
      onClick={onRowClick}
      className={classNames('hover:bg-grey-3', SHARED_PANEL_ITEM_CLASS_NAMES, !!onRowClick && 'cursor-pointer')}
      previewLoader={previewLoader}
      avatar={avatar ?? <FileStatusThumbnail ext={ext} status={status} thumbnailSource={thumbnailSource} />}
      title={<FileStatusTitle>{title}</FileStatusTitle>}
      subtitle={
        <FileStatusSubtitle className="truncate">
          <VisuallyHidden>with {title}</VisuallyHidden>
          <span aria-hidden="true">Error:</span> {error}
        </FileStatusSubtitle>
      }
      buttons={
        <>
          {onRetry && (
            <FileStatusButton onClick={() => onRetry(item)} label="Retry">
              <Refresh className="size-4" />
            </FileStatusButton>
          )}
          {onCancel && (
            <FileStatusButton onClick={() => onCancel(item)} label="Cancel">
              <Close className="size-4" />
            </FileStatusButton>
          )}
        </>
      }
    />
  ),
  isEqual,
);

FileStatusTrackingPanelListItemErrored.displayName = 'FileStatusTrackingPanelListItemErrored';
