import { FileStatusPanelItem, FileStatusSubtitle, FileStatusTitle } from '@air/feature-file-status/ui';
import classNames from 'classnames';
import { memo } from 'react';

import { getSubtitleWithProgress } from '../../utils';
import { FileStatusThumbnail } from './FileStatusThumbnail';
import { FileTrackingItem } from './FileStatusTrackingPanel';

export type FileStatusPanelListItemCompletedProps = {
  item: FileTrackingItem;
};

export const FileStatusTrackingPanelListItemCompleted = memo(
  ({
    item: { ext, onRowClick, previewLoader, avatar, title, thumbnailSource, sizeInBytes, subtitle, status },
  }: FileStatusPanelListItemCompletedProps) => {
    return (
      <FileStatusPanelItem
        onClick={onRowClick}
        className={classNames(
          'mx-1.5 rounded px-2.5 py-1.5 opacity-60 transition-all duration-200',
          !!onRowClick && 'cursor-pointer hover:bg-grey-3',
        )}
        previewLoader={previewLoader}
        avatar={avatar ?? <FileStatusThumbnail ext={ext} status={status} thumbnailSource={thumbnailSource} />}
        title={<FileStatusTitle>{title}</FileStatusTitle>}
        subtitle={
          <FileStatusSubtitle className="truncate">
            {subtitle ?? getSubtitleWithProgress({ title: 'Completed', uploadedBytes: sizeInBytes, sizeInBytes })}
          </FileStatusSubtitle>
        }
        buttons={null}
      />
    );
  },
);

FileStatusTrackingPanelListItemCompleted.displayName = 'FileStatusTrackingPanelListItemCompleted';
