import {
  FileStatusButton,
  FileStatusPanelItem,
  FileStatusSubtitle,
  FileStatusThumbnail,
  FileStatusTitle,
  FileTrackingItem,
} from '@air/feature-file-status/ui';
import { getSubtitleWithProgress } from '@air/feature-file-status/utils';
import { Close, Play, Skip } from '@air/next-icons';
import classNames from 'classnames';
import { memo } from 'react';
import isEqual from 'react-fast-compare';

import { SHARED_PANEL_ITEM_CLASS_NAMES } from '../../constants';

export interface FileStatusTrackingPanelListItemPausedProps {
  item: FileTrackingItem;
  onCancel?: (item: FileTrackingItem) => void;
  onResume?: (item: FileTrackingItem) => void;
  onSkip?: (item: FileTrackingItem) => void;
}

export const FileStatusTrackingPanelListItemPaused = memo(
  ({
    onSkip,
    onCancel,
    onResume,
    item,
    item: {
      title,
      onRowClick,
      previewLoader,
      avatar,
      thumbnailSource,
      subtitle,
      sizeInBytes,
      ext,
      currentBytesUploaded,
      status,
    },
  }: FileStatusTrackingPanelListItemPausedProps) => (
    <FileStatusPanelItem
      onClick={onRowClick}
      previewLoader={previewLoader}
      className={classNames('hover:bg-grey-3', SHARED_PANEL_ITEM_CLASS_NAMES, !!onRowClick && 'cursor-pointer')}
      avatar={avatar ?? <FileStatusThumbnail ext={ext} status={status} thumbnailSource={thumbnailSource} />}
      title={<FileStatusTitle>{title}</FileStatusTitle>}
      subtitle={
        <FileStatusSubtitle className="truncate">
          {subtitle ?? getSubtitleWithProgress({ title: 'Paused', uploadedBytes: currentBytesUploaded, sizeInBytes })}
        </FileStatusSubtitle>
      }
      buttons={
        <>
          {onResume && (
            <FileStatusButton onClick={() => onResume(item)} label="Resume">
              <Play className="size-4" />
            </FileStatusButton>
          )}
          {onSkip && (
            <FileStatusButton onClick={() => onSkip(item)} label="Skip">
              <Skip className="size-4" />
            </FileStatusButton>
          )}
          {onCancel && (
            <FileStatusButton onClick={() => onCancel(item)} label="Cancel">
              <Close className="size-4" />
            </FileStatusButton>
          )}
        </>
      }
    />
  ),
  isEqual,
);

FileStatusTrackingPanelListItemPaused.displayName = 'FileStatusTrackingPanelListItemPaused';
